import React from 'react';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
const Body = () => {
  return (
    <ReactPlayer
      className="react-player"
      playing={true}
      muted={true}
      loop={false}
      url="/index.m3u8"
      width="500px"
      height="100%"
      onStart={() => {
        console.log('Video started');
      }}
      onEnded={() => {
        console.log('Video Ended');
      }}
    />
  );
};

const rootElement = document.getElementById('root');
ReactDOM.render(<Body />, rootElement);
